import { useEffect, useState } from 'react';
import useAnalyticsStore from '@/stores/analyticsStore';
import useUserStore from '@/stores/userStore';
import { TNavLinkClickParams } from '@/types/TNavLinkClickParams';
import { FREE_PLAN_TYPE_ID } from './planTypeId';

export enum AdSource {
  HOME_TRENDING_TOKENS_TABLE = 'Home Trending Tokens Table',
  HOME_BANNER = 'Home Banner',
  TOKEN_PAGE = 'Token Page',
}

export const useAds = (source: AdSource) => {
  const [isPaidUser, setIsPaidUser] = useState(false);

  const userStageLevel = useUserStore((state) => state.stage?.level ?? 0);
  const tracker = useAnalyticsStore((state) => {
    return state.tracker;
  });

  const handleOnAdClick = async ({ href, text }: TNavLinkClickParams) => {
    tracker?.trackLinkClick(`${source} Ad: ${text} (${href})`);
  };

  useEffect(() => {
    const paidStatus = userStageLevel > FREE_PLAN_TYPE_ID;
    setIsPaidUser(paidStatus);
  }, [userStageLevel]);

  return { isPaidUser, handleOnAdClick };
};
