import React from 'react';
import Link from 'next/link';
import { ButtonLink } from 'moralis-ui';
import { TokenAdsView } from '@/services/models/GetTokenAdsViewDto';
import { faRectangleAd } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TokenAdBanner } from './TokenAdBanner';
import styles from './HomeTokenAd.module.scss';

export const HomeTokenWithoutLink = ({ tokenAdsView }: { tokenAdsView: TokenAdsView }) => {
  return (
    <div className={styles.mainContainer}>
      <TokenAdBanner tokenAdsView={tokenAdsView} />
      <Link className={styles.adsWithUslink} href="/marketplace/crypto-ads">
        <ButtonLink size="xs">
          <FontAwesomeIcon className={styles.socialMediaIcon} icon={faRectangleAd} />
          Advertise With Us
        </ButtonLink>
      </Link>
    </div>
  );
};
