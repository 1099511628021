import React from 'react';
import Link from 'next/link';
import { ButtonLink } from 'moralis-ui';
import { TokenAdsView } from '@/services/models/GetTokenAdsViewDto';
import { AdSource, useAds } from '@/utils/useAds';
import { faRectangleAd } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomeTokenWithoutLink } from './HomeTokenWithoutLink';
import { TokenAdBanner } from './TokenAdBanner';
import styles from './HomeTokenAd.module.scss';

export const HomeTokenAd = ({ tokenAdsView }: { tokenAdsView: TokenAdsView }) => {
  const { isPaidUser, handleOnAdClick } = useAds(AdSource.HOME_BANNER);
  const hasCta = Boolean(tokenAdsView?.cta?.text);
  const hasTokenUrl = hasCta ? tokenAdsView?.cta?.link : tokenAdsView.tokenURL;

  const hrefLinkCta = hasCta ? tokenAdsView?.cta?.link : tokenAdsView.tokenURL;

  // If we get a paid user we won't show any ads
  if (isPaidUser) return;

  if (!hasTokenUrl) return <HomeTokenWithoutLink tokenAdsView={tokenAdsView} />;

  return (
    <div className={styles.mainContainer}>
      <Link
        className={styles.link}
        href={hrefLinkCta}
        target={hasCta ? '_blank' : '_self'}
        onClick={(event) => {
          handleOnAdClick({
            href: hrefLinkCta,
            text: tokenAdsView.pitch,
            event,
            target: hasCta ? '_blank' : '_self',
          });
        }}
      >
        <TokenAdBanner tokenAdsView={tokenAdsView} />
      </Link>
      <Link className={styles.adsWithUslink} href="/marketplace/crypto-ads">
        <ButtonLink size="xs">
          <FontAwesomeIcon className={styles.socialMediaIcon} icon={faRectangleAd} />
          Advertise With Us
        </ButtonLink>
      </Link>
    </div>
  );
};
